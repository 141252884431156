<template>
  <div id="verified">
    <van-nav-bar title="实名认证" left-arrow :border="false" @click-left="goLastPage">
    </van-nav-bar>
    <div class="idCard">
      <van-cell title="真实姓名" size="large">
        <template #right-icon>
          <input type="text" placeholder="输入身份证号上姓名" class="urgentPhone" v-model="id_card_name" />
        </template>
      </van-cell>
      <van-cell title="身份证号" size="large">
        <template #right-icon>
          <input type="text" placeholder="输入身份证号" class="urgentPhone" v-model="id_card_number" oninput="if(value.length>5)value=value.slice(0,18)" />
        </template>
      </van-cell>
    </div>
    <p class="idTips">注意：体现账户必须与身份证一致</p>
    <div class="upDate">
      <div class="date">
        <van-uploader :after-read="onRead">
          <div class="upDateBac onLoading" v-if="front_show">
            <van-loading />
          </div>
          <div class="upDateBac" :style="front_path" v-else>
          </div>
        </van-uploader>
        <p>传入身份证正面</p>
      </div>
      <div class="date">
        <van-uploader :after-read="onRead1">
          <div class="upDateBac onLoading" v-if="rearshow">
            <van-loading />
          </div>
          <div class="upDateBac" :style="rear_path" v-else>
          </div>
        </van-uploader>
        <p>传入身份证反面</p>
      </div>
    </div>
    <div class="upDate">
      <div class="date">
        <van-uploader :after-read="onRead2">
          <div class="upDateBac onLoading" v-if="hand_show">
            <van-loading />
          </div>
          <div class="upDateBac" :style="hand_path" v-else>
          </div>
        </van-uploader>
        <p>上传手持身份证照片</p>
      </div>
      <div class="date">
        <p class="userTip">1.需本人上传免冠照</p>
        <p class="userTip">2.照片五官清晰可见</p>
        <p class="userTip">3.照片身份证清晰可见</p>
      </div>
    </div>
    <div class="positionBottom">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="submitVerified">
        提交审核
      </van-button>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import TopBar from "@/components/home/TopBar.vue";
export default {
  components: {
    TopBar,
  },
  data() {
    return {
      front_path: `background: url(${require("../../../assets/images/idcard1.png")}) no-repeat center / cover`,
      rear_path: `background: url(${require("../../../assets/images/idcard2.png")}) no-repeat center / cover`,
      hand_path: `background: url(${require("../../../assets/images/idcard3.png")}) no-repeat center / cover`,
      front_show: false,
      rearshow: false,
      hand_show: false,
      front_image: "",
      rear_image: "",
      hand_image: "",
      id_card_number: "",
      id_card_name: "",
    };
  },
  methods: {
    onRead(file) {
      this.front_show = true;
      let image = new FormData();
      image.append("image", file.file);
      this.$http
        .post("/api/worker/uploadIdCard", image, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res);
          this.front_path = `background:url(${this.cdnHttp}${res.data.result.path}) no-repeat center / cover`;
          this.front_image = res.data.result.path;
          this.front_show = false;
        });
    },
    onRead1(file) {
      this.rearshow = true;
      let image1 = new FormData();
      image1.append("image", file.file);
      this.$http
        .post("/api/worker/uploadIdCard", image1, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.rear_image = res.data.result.path;
          this.rear_path = `background:url(${this.cdnHttp}${res.data.result.path}) no-repeat center / cover`;
          this.rearshow = false;
        });
    },
    onRead2(file) {
      this.hand_show = true;
      let image2 = new FormData();
      image2.append("image", file.file);
      this.$http
        .post("/api/worker/uploadIdCard", image2, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          this.hand_image = res.data.result.path;
          this.hand_path = `background:url(${this.cdnHttp}${res.data.result.path}) no-repeat center / cover`;
          this.hand_show = false;
        });
    },
    submitVerified() {
      if (
        !/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(
          this.id_card_number
        )
      ) {
        Toast.fail("请输入\n正确身份证信息");
      } else if (
        this.front_image == "" ||
        this.rear_image == "" ||
        this.hand_image == ""
      ) {
        Toast.fail("请上传\n完整的验证照片");
      } else {
        this.$http
          .post("/api/v2/worker/profile/id_card", {
            id_card_number: this.id_card_number,
            id_card_name: this.id_card_name,
            front_image: this.front_image,
            rear_image: this.rear_image,
            hand_image: this.hand_image,
          })
          .then((res) => {
            if (res.data.code === 0) {
              Toast.success("身份上传成功!\n等待审核");
              this.goSubPageR("home");
              // this.goLastPage();
            }
          })
          .catch((err) => {
            Toast(err.error);
          });
      }
    },
  },
};
</script>
<style lang="less">
#verified {
  .idCard {
    margin-top: 1rem;
    .urgentPhone {
      width: 60%;
      border: none;
    }
  }
  .idTips {
    padding: 1rem 0 0 0;
    text-align: center;
    font-size: 1.4rem;
    color: #bc8686;
  }
  .upDate {
    width: 100%;
    padding: 1rem;
    margin: 1rem 0 0 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #fff;
    .date {
      width: 25rem;
      margin: 1rem;
      background: #fff;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .userTip {
        padding: 0.5rem 0 0 0;
        text-align: left;
      }
      p {
        text-align: center;
        padding: 1rem 0 0 0;
        font-size: 1.4rem;
      }
      .upDateBac {
        width: 100%;
        height: 10rem;
        &.onLoading {
          background: none;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          width: 100%;
        }
      }
      .van-uploader,
      .van-uploader__wrapper,
      .van-uploader__input-wrapper {
        width: 100%;
      }
      .van-uploader__preview {
        margin: 0;
      }
      .van-uploader__preview-image {
        width: 100% !important;
      }
      .van-uploader__upload {
        width: 100% !important;
        margin: 0 !important;
      }
    }
  }
  .positionBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>>